import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const primary = {
  light: "#4CB4E7",
  main: "#3F7CBF",
  dark: "#004B79",
  contrastText: "#fff",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4CB4E7",
      main: "#3F7CBF",
      dark: "#004B79",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e8308a",
      main: "#e8308a",
      dark: "#c92274",
      contrastText: "#fff",
    },
    text: {
      primary: "#514947",
    },
  },
  typography: {
    fontFamily: ["Arial", "sans-serif", "-apple-system"].join(","),
    fontSize: 16,
    body1: {
      fontSize: "17px",
      lineHeight: 1.7,
    },
    body2: {
      padding: 0,
      margin: 0
    },
    h1: {
      fontSize: "3.33rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    h2: {
      fontSize: "2.33rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    h3: {
      fontSize: "2rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    h4: {
      fontSize: "1.833rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    h5: {
      fontSize: "1.6rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    h6: {
      fontSize: "1.3rem",
      fontFamily: ["Arial-rounded"].join(","),
    },
    subtitle1: { fontFamily: "arial", fontWeight: 600, fontSize: "1rem", color: "#515151" },
    subtitle2: { color: "rgb(117, 117, 117)", fontSize: "14px" },
    button: {
      textTransform: "none",
      fontFamily: "Arial-rounded",
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiListSubheader: {
      style: { fontWeight: 600, fontFamily: "Arial-rounded", color: primary.light },
      disableSticky: true,
    },
    MuiMenuItem: {
      style: { whiteSpace: "unset", wordBreak: "break-word" },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme.overrides = {
  MuiTypography: {
    h1: {
      color: theme.palette.primary.dark,
    },
    h2: {
      color: theme.palette.primary.dark,
    },
    h3: {
      color: theme.palette.primary.dark,
    },
    h4: {
      color: theme.palette.primary.dark,
    },
    h5: {
      color: theme.palette.primary.light,
    },
    h6: {
      color: theme.palette.primary.light,
    },
  },
};

export default responsiveFontSizes(theme);
// export default theme
